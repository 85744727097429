.bible-audio-banner {
  position: relative;
  width: 100%;
  height: 70vh;
  background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    url("../images/sermon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s;
  background-position: center;
}

.bible-audio-banner p {
  color: #fff;
  height: 60vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 80%;
  font-size: 18px;
  text-align: end;
}
@media only screen and (max-width: 768px) {
  .bible-audio-banner p {
    width: 95%;
  }
}

.bible-audio-container {
  background: -webkit-linear-gradient(to right, #fdfcfb, #e2d1c3);
  background: linear-gradient(to right, #fdfcfb, #e2d1c3);
  padding-top: 5em;
  padding-bottom: 4em;
}

.testament-container {
  display: flex;
  width: 70%;
  margin: 0 auto;
}

.testament-container > span {
  padding: 10px;
  cursor: pointer;
}

.audio-outer-container {
  display: flex;
  width: 70%;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
}
@media only screen and (max-width: 768px) {
  .audio-outer-container {
    flex-direction: column;
    align-items: start;
  }
}

.audio-outer-container img {
  margin-right: 20px;
}

.audio-container {
  display: flex;
  flex-direction: column;
}

.audio-details {
  padding: 5px;
}

.books-container {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
}

.books-menu-outer-container {
  display: flex;
}

.books-menu-container {
  display: flex;
  flex-direction: column;
  min-width: 140px;
  max-width: 140px;
}

.book {
  cursor: pointer;
  padding: 5px;
}

.audio-list-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
}

.audio-list-container span {
  padding: 5px;
  width: fit-content;
}

.page {
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  color: #fff;
  background: #e67e22;
  height: fit-content;
  border-radius: 4px;
}
