.pdf-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    transition: 0.5s;
}

.pdf-popup-container {
    width: 100%;
    height: 90%;
}

.pdf-popup-container iframe {
    width: 100%;
    height: 100%;
}