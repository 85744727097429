.sermon-banner {
    position: relative;
    width: 100%;
    height: 70vh;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ), url("../images/sermon.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s;
    background-position: center;
}

.sermon-banner p {    
    color: #fff;
    height: 60vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 80%;
    font-size: 18px;
    text-align: end;
}

.sermon-container {
    background: -webkit-linear-gradient(to right, #fdfcfb, #e2d1c3); 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
    padding-top: 5em;
    padding-bottom: 4em;
}

.sermon-header-container {
    width: 55%;
    margin: 0 auto;
}

.sermon-container h1 {
    font-family: 'Do Hyeon', sans-serif;    
}

.sermon-tab-year-container {
    display: flex;
}

.sermon-tab-year {
    padding: 10px;
    cursor: pointer;        
}

.sermon-lists {
    height: 100%;
    width: 80%;
    margin: 0 auto;

}

.sermon-lists tr {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.sermon-lists th {
    display: flex;
    margin: 0.3em;
    width: 300px;
    height: 200px;
    cursor: pointer;
}

.sermon-lists th:hover {
    filter: brightness(50%);
    transition: 0.5s;
}

@media only screen and (max-width: 1024px) {
    .sermon-banner p {
        width: 90%;
        font-size: 16px;
    }
}

@media only screen and (max-width: 800px) {
    .sermon-container h1  {
        padding-left: 0;
        text-align: center;
    }
    .sermon-tab-year-container {
        justify-content: center;
    }
}


