.space375, .space320, .space411 {
    display: none;
}
.news-header {
    width: 100%;
    height: 70vh;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1) ), url("../images/news.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.news-title {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;    
    height: 60vh;
    font-size: 18px;
    width: 60%;
}

.announcement-outer-container {
    background: -webkit-linear-gradient(to right, #fdfcfb, #e2d1c3); 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
    padding: 5em 10em 10em 10em;
    color: #333;
    display: flex;
    justify-content: space-between;
}

.announcement-container {
    width: 100%;
    font-family: 'Do Hyeon', sans-serif;
    font-size: 18px;
}

.announcements-place-detail-container {
    font-size: 25px;
}

.announcement-header {
    display: flex;
    width: 100%;
    padding-left: 5em;
    padding-bottom: 2em;
}

.news-liner {
    border-top: 5px solid;
    width: 5%;
    margin: 0 auto;
    padding-bottom: 2em;
}

.announcements {
    display: flex;
    padding: 1em 0 1em 0;
    padding-left: 3em;
    align-items: center;
}

.date-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 170px;
    height: 170px;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    font-size: 23px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.announcements-place-detail-container p {
    /* line-height: 0em; */
    margin-left: 2em;
    margin-bottom: 0.3em;

}

.announcements-date-place, .announcements-title {
    font-weight: bold;
}

.weekly-paper {
    display: flex;
    justify-content: center;
    margin-top: 3em;
}

.weekly-paper span {
    padding: 1em 2em;
    cursor: pointer;
    background: #2471A3;
    color: #fff;
    transition: 0.5s;
}


.pdf-popup-btn {
    padding: 1em;
    cursor:pointer;
    background: #333;
    color: #fff;
    transition: 0.5s;
}

.weekly-paper span:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.announcements-other {
    width: 30%;
    border-left: 2px solid #333;
    font-family: 'Do Hyeon', sans-serif;
}

.announcements-other-header {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1200px) {
    .announcement-outer-container {
        padding: 10em 2em 10em 2em;
    }
}

@media only screen and (max-width: 1024px) {
    .announcement-outer-container {
        display: inherit;

    }
    .announcement-container {
        width: 100%;
    }
    .announcements-other {
        margin-top: 3em;
        width: 100%;
        border-left: 0;
    }
    .announcement-header {
        justify-content: center;
        padding-left: 0;
    }
    .announcements {
        padding-left: 0;
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 950px) {
    .announcements-place-detail-container, .date-box {
        font-size: 20px;
    }
}
@media only screen and (max-width: 850px) {
    .announcements {
        width: 95%;
        justify-content: center;
    }
    .date-box-container {
        width: 25%;
        display: flex;
        justify-content: center;
    }
    .date-box {
        width: 150px;
        height: 150px;
    }
    .announcements-place-detail-container {
        width: 100%;
    }
}

@media only screen and (max-width: 750px) {
    .date-box {
        height: 150px;
        width: 100px;
        font-size: 20px;
    }

    .announcement-outer-container {
        padding: 5em 0 5em 0;
    }
    .announcements-place-detail-container {
        font-size: 17px;
    }
    .announcements-place-detail-container p {
        margin-left: 1em;
    }
    .announcements {
        justify-content: space-around;
    }
    /* .announcements-place-detail-container span {
        padding-left: 0;
    } */
    .news-title {
        width: 100%;
        text-align: end;
    }
}

@media only screen and (min-width: 1500px) {
    .announcements {
        padding-left: 7em;
    }
    .announcement-header {
        padding-left: 17em;
    }
}

@media only screen and (max-width: 500px) {
    .news-title {
        font-size: 16px;
    }
    .date-box {
        height: 120px;
        width: 70px;
        font-size: 15px;
    }
    .announcements-place-detail-container {
        font-size: 16px;
    }

}

@media only screen and (max-width: 414px) {
    .space411 {
        display: initial;
    }
}

@media only screen and (max-width: 375px) {
    .space411 {
        display: none;
    }
    .space375 {
        display: initial;
    }
}

@media only screen and (max-width: 320px) {
    .space411 {
        display: none;
    }
    .space375 {
        display: none;
    }
    .space320 {
        display: initial;
    }
    .date-box {
        width: 65px;
    }
    .announcements-detail {
        font-size: 13px;
    }
}