.last-weekly-update-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    transition: 0.5s;
}

.last-weekly-update-popup {
    display: flex;
    flex-direction: column;
}

.last-weekly-update-container button {
    background: #333;
    color: #fff;
    font-size: 14px;
}


.last-weekly-update-container img {
    width: 900px; 
    height: 90vh;
}

/* @media only screen and (min-width: 1441px) {
    .last-weekly-update-container img {
        width: 1000px;
        height: 650px
    }
} */

@media only screen and (max-width: 1024px) {
    .last-weekly-update-container img {
        width: 700px;
        height: auto;
    }
}

@media only screen and (max-width: 700px) {
    .last-weekly-update-container img{
        width: 550px;
        /* height: 650px; */
    }
}

@media only screen and (max-width: 600px) {
    .last-weekly-update-container img {
        width: 450px;
        /* height: 650px */
    }
}

@media only screen and (max-width: 500px) {
    .last-weekly-update-container img {
        width: 100%;
        /* height: 650px */
    }

}

@media only screen and (max-width: 450px) {
    .last-weekly-update-container img {
        width: 100%;
        height: auto;
    }

}