.about-header {
    width: 100%;
    height: 70vh;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ), url("../images/about.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s;
    background-position: 75%;
}


.header-title {
    position: absolute;
    color: #fff;
    text-align: center;
    top: 27vh;
    /* padding-right: 2.5em; */
    right: 5vh;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    top: 20vh; */
    /* height: 50vh; */
    /* padding-top: 100px; */
    /* margin-left: 1em;
    transform: translateX(-6%); */

}

.about-header p {
    font-size: 40px;
}

.header-pop {
    position: absolute;
    color: #fff;
    text-align: center;
    top: 37vh;
    /* left: 5vh; */
    /* padding-left: 2.5em; */
    /* right: 10%; */
    /* left: 5%; */
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; */
    /* height: 10vh; */
    /* padding-top: 100px; */
    /* margin-left: 1em; */
    /* transform: translateX(-6%); */
}

/* .about-header:hover > .header-title {
    transform: translateX(-6%);
} */

/* .about-header:hover > .header-pop {
    transform: translateY(-20vh);
    opacity: 1;
} */

/* .hover-space:hover ~ .header-pop {
    transform: translateY(-20vh);
    opacity: 1;
}

.hover-space {
    width: 100%;    
    border: 170px solid transparent;
    
} */

.about-mission-statement-container {
    padding: 0em 0 8em 0;
    width: 100%;    
    background: -webkit-linear-gradient(to right, #fdfcfb, #e2d1c3); 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
}

.about-mission-statement-welcome {
    display: flex;
    height: 350px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Indie Flower', cursive;
    font-size: 60px;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1) ),url('../images/newFamily.jpg'); */
    /* background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */
    /* border-top: 1px solid #fff; */
    color: #333;
}

.about-liner {
    border-bottom: 4px solid;
    width: 7%;
    margin: 0 auto;
    margin-bottom: 5em;
    /* margin-top: 1em; */
}

.about-mission-statement {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
    font-size: 18px;
    /* background-color: #F1EFD6; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 2em;
}

.about-images-container {
    flex-direction: row;
}

.pastor-image {
    width: 280px;
    height: 250px;
    padding-bottom: 2em;
}


.about-mission-statement h3 {
    border-left: 5px solid #333;
    padding-left: 15px;
}

.about-mission-statement p {
    padding-left: 20px;
}



.new-family {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.new-family-container h1 {
    padding-bottom: 1em;
}


.box {
    height: 100px;
    width: 100px;
    background: #fff;
}


@media only screen and (max-width: 850px) {
    /* .about-header {
        margin-left: 0;
    } */
    .about-mission-statement-welcome {
        background-attachment: initial;
    }
    .about-mission-statement h3 {
        font-size: 22px;
    }
    .about-mission-statement {
        font-size: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .about-mission-statement {
        width: 90%;
    }
    .about-mission-statement p {
        font-size: 14px;
    }
    
    .about-mission-statement h3 {
        font-size: 20px;
    }
    .about-images-container {
        display: flex;
        justify-content: center;
    }
    .pastor-image {
        width: 180px;
        height: 190px;
    }
    .about-header p {
        font-size: 35px;
    }
    /* .header-title {
        margin-right: 2em;
    } */
    /* .header-pop {
        transform: translateY(-210%);
        opacity: 1;
        left: 35%;
    } */
}

@media only screen and (max-width: 400px) {
    .hover-space {
        border: 150px solid transparent;
    }
    
    .about-mission-statement h3 {
        font-size: 18px;
    }
}