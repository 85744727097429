.space500 {
    display: none;
}

.footer-container {
    width: 100%;
    height: 100px;
    background-color: rgba(35, 31, 33);
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 3em 0 8em;
}

.footer-left {
    display: flex;
    align-items: center;
    
}

.footer-middle {
    text-align: center;
    line-height: 1.4em;
}

.footer-right {
    display: flex;
    text-align: end;
}

.contact {
    margin-right: 1em;
    
}

.contact > p > span {
    color: #fff;
    font-weight: bold;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.contact-info {
    text-align: start;
}

.footer-container p {
    font-size: 14px;
    margin-bottom: 0;
}

.resp-footer-container {
    display: none;
    width: 100%;
    height: fit-content;
    background-color: rgba(35, 31, 33);
    color: #fff;
    padding: 2em 0;
    font-size: 14px;
}



@media only screen and (max-width: 1100px) {
    .resp-footer-container, .resp-footer-top, .resp-footer-middle, .resp-footer-end {
        display: inherit;
        padding: 1em;
    }
    .footer-container {
        display: none;
    }
    .contact footerlist {
        width: 100px;
    }
}

@media only screen and (max-width: 500px) {
    .space500 {
        display: initial;
    }
}