#korean {
  font-family: 'Nanum Gothic', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: Arial,Helvetica,sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Nanum Gothic', sans-serif; */
  /* font-family: 'Rubik', sans-serif; */
  /* font-family: 'Noto Sans KR', sans-serif; */
  /* font-family: 'Nanum Myeongjo', serif; */
  font-family: 'Gothic A1', sans-serif;
  
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
