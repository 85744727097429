#korean {
  font-family: 'Nanum Gothic', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: Arial,Helvetica,sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Nanum Gothic', sans-serif; */
  /* font-family: 'Rubik', sans-serif; */
  /* font-family: 'Noto Sans KR', sans-serif; */
  /* font-family: 'Nanum Myeongjo', serif; */
  font-family: 'Gothic A1', sans-serif;
  
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-container {
    display: -webkit-flex;
    display: flex;
    position: fixed;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    z-index: 2;
    /* background-color: transparent; */
    height: 75px;
    -webkit-align-items: center;
            align-items: center;
    /* border-bottom: 1px solid #A6ACAF; */
    transition: 0.5s;
}

.nav {
    padding-right: 10em;
}

.nav-link {
    color: #fff;
    padding: 0 3em 0 0.5em;
    display: inline;
    display: initial;
    border-left: 4px solid #2471A3;
    font-weight: 600;
    transition: 0.5s;
}

.nav-link::before {
    border-right: 2px solid;
}

.nav-link:hover, .nav-home:hover, .resp-nav-home:hover {
    color: #5499C7 !important;
    text-decoration: none;
}

.nav-home, .resp-nav-home {
    margin-left: 10em;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    transition: 0.5s;
    color: #fff;
    line-height: 1em;
}

.resp-nav-home {
    width: 150px;
}

.nav-home img, .resp-nav-home img {
    width: 20px;
    height: 20px;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
}

.resp-nav-home {
    display: none;
}

.JGCC {
    font-size: 14px;
    margin-left: 22px;
}

.JGCC span {
    color: #E67E22
}

.nav-logo {
    width: 160px;
    height: 70px;
    margin-left: 5em;
}

.nav-menu {
    font-size: 30px;
    cursor: pointer;
    display: none;
    padding-right: 5em;
    color: #fff;
}

@media only screen and (max-width: 1300px) {
    .nav {
        display: none;
    }
    .nav-menu {
        display: inline;
        display: initial;
    }
}

@media only screen and (max-width: 1024px) {
    .resp-nav-home {
        display: inline;
        display: initial;
    }
    .nav-home {
        display: none;
    }
}

@media only screen and (max-width: 850px) {
    .resp-nav-home {
        margin-left: 3em;
    }
    .nav-menu {
        padding-right: 3em;
    }
}

@media only screen and (max-width: 450px) {
    .nav-home, .resp-nav-home {
        font-size: 13px;
    }
    .nav-menu {
        padding-right: 1.5em;
    }
}

.resp-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 75px;
    width: 100%;
    background: #333;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding-right: 3em;
    padding-bottom: 3em;
    transition: 0.5s;
}

.resp-nav-item {
    list-style: none;
    padding: 1em 0 1em 1em;
    font-size: 20px;
    text-decoration: none;
    width: 200px;
}

.resp-nav-item a {
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
    border-left: 4px solid white;
    padding-left: 1em;
    width: 150px;
}


.resp-nav-item a:hover {
    color: #5499C7;
}

@media only screen and (min-width: 1300px) {
    .resp-nav {
        display: none;
    }
}

@media only screen and (max-width: 850px) {
    .resp-nav {
        padding-right: 0;
    }
    .resp-nav-item {
        width: 180px
    }
}

@media only screen and (max-width: 450px) {
    .resp-nav-home {
        margin-left: 1.5em;
    }
    .resp-nav-item {
        width: 160px;
    }
}
.sermon-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 10000;
}

.sermon-popup-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1em;
  background-color: #fff;
}

.sermon-video-popup-container {
  width: 50%;
}

.sermon-video-popup-container video {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  outline: none;
}

.sermon-close,
.sermon-video-close {
  background: transparent;
  border: none;
  font-size: 20px;
  float: right;
}

.sermon-video-close span {
  color: #fff;
  outline: none;
  transition: 0.3s;
}

.sermon-close span {
  color: #333;
  outline: none;
  transition: 0.3s;
}

.sermon-close span:hover,
.sermon-video-close span:hover {
  color: #e67e22;
  outline: none;
}

.sermon-popup-container img {
  border-radius: 50%;
}

.sermon-popup-container p {
  padding-left: 1.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 0;
}

.sermonVideoRefButton {
  border-style: none;
  padding: 0.3em;
  margin-top: 0.3em;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.sermon-popup-container audio {
  padding-top: 0.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.sermon-popup-container audio:focus {
  outline: none;
}
@media only screen and (max-width: 800px) {
  .sermon-video-popup-container {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .sermon-video-popup-container {
    width: 100%;
  }
}

.space {
  display: none;
}

.slick-slider {
  height: 85vh;
}

.slick-dots {
  bottom: -35px;
}

.slick-dots li button:before {
  font-size: 15px !important;
  line-height: 20px;
}

.carousel-one-image {
  background: url(/static/media/carouselOne.96284b46.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 85vh;
}

.carousel-one {
  overflow-y: hidden;
  color: #fff;
  padding-top: 28vh;
  padding-left: 47vh;
  height: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* align-items: center;
    text-align: center;
    padding-left: 0; */
}

.carousel-one-title {
  font-size: 40px;
  font-weight: bold;
}

.carousel-one-title-top {
  font-size: 18px;
  font-weight: normal;
  font-weight: initial;
}

.carousel-one-subtitle {
  width: 80%;
}

.carousel-liner {
  display: block;
  width: 10%;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  border-top: 5px solid #fff;
}

/* .carousel-one-subtitle {
    font-size: 25px;
    width: fit-content;
    text-align: center;
} */
.cos-one {
  font-size: 20px;
  /* border-left: 5px solid;
    padding-left: 10px; */
}

.cos-two {
  font-size: 18px;
}

.carousel-one-subtitle span {
  line-height: 2em;
}

/* Carousel Two css */

.carousel-two-image {
  background: url(/static/media/carouselTwo.a1c0ba02.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 85vh;
  width: 90%;
}

.carousel-two {
  overflow-y: hidden;
  color: #fff;
  padding-top: 22vh;
  padding-left: 47vh;
  height: inherit;
}

.carousel-two-title {
  font-size: 40px;
  font-weight: bold;
}

.carousel-two-title-top {
  font-size: 20px;
  font-weight: normal;
  font-weight: initial;
}

.carousel-two-subtitle {
  font-size: 22px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.carousel-two-subtitle span {
  line-height: 2em;
}

/* Carousel Three Css */

.carousel-three-image {
  background: url(/static/media/carouselThree.607c1233.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 85vh;
  width: 90%;
}

.carousel-three {
  overflow-y: hidden;
  background-color: rgba(160, 160, 160, 0.4);
  color: #fff;
  padding-top: 30vh;
  /* padding-left: 47vh; */
  height: inherit;
}

.carousel-three-content {
  width: 53%;
}

.carousel-three-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: 20px;
  width: 90%;
  margin: 0 auto;
  padding-top: 8rem;
}

.carousel-three-title {
  font-size: 40px;
  font-weight: bold;
  color: green;
}

.carousel-three-subtitle {
  margin-bottom: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.carousel-three-subtitle-breakpoint {
  display: none;
}

.carousel-three-subtitle span {
  line-height: normal !important;
}

.carousel-three-title-top {
  font-size: 20px;
  font-weight: normal;
  font-weight: initial;
}

.carousel-three-subtitle {
  font-size: 34px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.carousel-three-subtitle span {
  line-height: 2em;
}

/* caroulel four */

.carousel-four-image {
  background: url(/static/media/carouselFour.05e39dc9.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 85vh;
  width: 90%;
}

.carousel-four {
  overflow-y: hidden;
  color: #fff;
  padding-top: 25vh;
  /* padding-left: 47vh; */
  height: inherit;
  font-family: "Nanum Myeongjo", serif;
  font-style: italic;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.carousel-four-title {
  margin-bottom: 3em;
}

.carousel-four-content {
  margin-bottom: 1em;
  font-size: 24px;
}

.carousel-three a,
.carousel-two a,
.carousel-one a,
.carousel-four a {
  display: inline-block;
  color: #fff;
  margin-top: 0.6em;
  padding: 0.3em 1.2em;
  background-color: #333;
  text-decoration: none;
  /* border: 1px solid #E67E22; */
  cursor: pointer;
  font-size: 22px;
}

@media only screen and (max-width: 1440px) {
  .carousel-one-title-top {
    font-size: 16px;
  }

  .carousel-one-title,
  .carousel-two-title,
  .carousel-three-title {
    font-size: 35px;
  }

  .carousel-two-subtitle,
  .carousel-three-subtitle {
    font-size: 20px;
  }

  .cos-one {
    font-size: 18px;
  }

  .cos-two {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1300px) {

  .carousel-two,
  .carousel-one {
    padding-left: 40vh;
  }
}

@media only screen and (max-width: 1200px) {
  .carousel-one {
    padding-left: 17vh;
  }
}

@media only screen and (max-width: 1024px) {
  .carousel-one {
    padding-left: 5vh;
  }

  .carousel-two {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding-left: 0;
  }

  .carousel-one-title-top,
  .carousel-two-title-top,
  .carousel-three-title-top {
    font-size: 18px;
  }

  .carousel-one-title,
  .carousel-two-title,
  .carousel-three-title,
  .carousel-three-title {
    margin-left: 0;
    font-size: 35px;
  }

  .carousel-two-subtitle,
  .carousel-three-subtitle {
    margin-left: 0;
    font-size: 20px;
  }

  .carousel-three-content {
    width: 80%;
  }
}

@media only screen and (max-width: 850px) {
  .carousel-one-subtitle {
    width: 90%;
  }

  .carousel-one-subtitle,
  .carousel-two-subtitle,
  .carousel-three-subtitle {
    font-size: 20px;
  }

  .carousel-three-content {
    font-size: 18px;
  }

  .carousel-one-subtitle p {
    margin-left: 20vh;
  }

  .carousel-three-container {
    font-size: 16px;
  }
}

@media only screen and (max-width: 650px) {

  .carousel-one-title-top,
  .carousel-two-title-top,
  .carousel-three-title-top {
    font-size: 16px;
  }

  .carousel-two-title,
  .carousel-three-title {
    font-size: 25px;
    margin-left: 0;
  }

  .carousel-one-subtitle p {
    margin-left: 15vh;
  }

  .carousel-one-subtitle,
  .carousel-two-subtitle,
  .carousel-three-subtitle {
    font-size: 18px;
    margin-left: 0;
  }

  .carousel-three-content {
    width: 95%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .space {
    display: inline;
    display: initial;
  }

  .carousel-three-subtitle-breakpoint {
    display: inline;
    display: initial;
  }
}

@media only screen and (max-width: 550px) {
  .carousel-one {
    padding-left: 2vh;
  }

  .carousel-one-title {
    font-size: 25px;
  }

  .cos-one,
  .carousel-one a,
  .carousel-two a,
  .carousel-three a {
    font-size: 17px;
  }

  .cos-two {
    font-size: 14px;
  }
}

@media only screen and (max-width: 375px) {
  .carousel-one {
    padding-top: 20vh;
  }

  .space {
    display: inline;
    display: initial;
  }
}

/* height settings for mobile */
.space500 {
    display: none;
}

.footer-container {
    width: 100%;
    height: 100px;
    background-color: rgba(35, 31, 33);
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 3em 0 8em;
}

.footer-left {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    
}

.footer-middle {
    text-align: center;
    line-height: 1.4em;
}

.footer-right {
    display: -webkit-flex;
    display: flex;
    text-align: end;
}

.contact {
    margin-right: 1em;
    
}

.contact > p > span {
    color: #fff;
    font-weight: bold;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.contact-info {
    text-align: start;
}

.footer-container p {
    font-size: 14px;
    margin-bottom: 0;
}

.resp-footer-container {
    display: none;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(35, 31, 33);
    color: #fff;
    padding: 2em 0;
    font-size: 14px;
}



@media only screen and (max-width: 1100px) {
    .resp-footer-container, .resp-footer-top, .resp-footer-middle, .resp-footer-end {
        display: inherit;
        padding: 1em;
    }
    .footer-container {
        display: none;
    }
    .contact footerlist {
        width: 100px;
    }
}

@media only screen and (max-width: 500px) {
    .space500 {
        display: inline;
        display: initial;
    }
}
.mission-container, .mission-community-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 70%;
    margin: 0 auto;
    padding-bottom: 3em;
    color: #333;
}

.outer-mission-container { 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
    padding-top: 5em
}

.mission-statement, .vision-statement {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 20px;
    -webkit-justify-content: center;
            justify-content: center;
}

.mission-statement h2, .mission-statement span {
    width: 100%;
    background: #154360;
    color: #fff;
    padding: 0.05em 0.5em;
    
    /* margin-bottom: 1em; */
    /* border-radius: 3px; */
}

.vision-logo-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

i {
    font-size: 110px;
}

.mission-image {
    width: 500px;
    height: 550px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.outer-service-container {
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/service.59f4e2b5.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 9em;
    padding-bottom: 9em;
    color: #ECF0F1;
    width: 100%;
}

.service-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 80%;
    margin: 0 auto;
}

.service {
    font-size: 40px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding: 0.5em 0 3em;
}

.service-liner {
    content: "";
    width: 5%;
    border-top: 5px solid #fff;
    margin: 0 auto;
    padding-bottom: 2em;
}

.service-time-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.service-sunday, .service-saturday, .service-friday {
    border-bottom: 1px solid;
    padding: 1em;
}

.service-type {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 0.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.service-type type {
    border-right: 4px solid #fff;
    line-height: 0.8;
    padding-right: 0.7em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.service-type span {
    font-size: 23px;
    font-weight: normal;
    padding-left: 0.7em;
}

.service-time {
    font-size: 20px;
}

.main-page-location-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 18px;
}

.main-page-location, .main-page-email, .main-page-phone {
    display: -webkit-flex;
    display: flex;
}

.main-page-location ul, .main-page-email ul, .main-page-phone ul {
    list-style: none;
    padding-left: 1em;
}

.main-page-location b, .main-page-email b {
    padding-bottom: 0.3em;
}

.main-page-email a, .main-page-location a {
    text-decoration: underline;
    text-underline-position: under;
    color: #ECF0F1;
    transition: 0.3s;
}

.main-page-email a:hover, .main-page-location a:hover {
    color: #5499C7;
}

.this-month-container {
    background: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3) ), url(/static/media/thisMonth.4f246eac.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    padding: 9em 0 9em 0;
    
    color: #fff;
    border-top: 1px solid #fff;
    width: 100%;
    height: auto;
}

.this-month-title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 50%;
    padding-bottom: 1em;
}

.this-month-title {
    font-size: 30px;
}

.this-month-scripture, .this-month-hymn {
    border-left: 5px solid;
    width: 65%;
    margin: 0 auto;
    font-size: 20px;
}

.this-month-word {
    width: 63%;
    margin: 0 auto;
    font-size: 20px;
    /* line-height: 1.5em; */
}


.this-month-scripture > *, .this-month-hymn > *, .this-month-word > * {
    padding-left: 2em;
}

.this-month-word-author {
    line-height: 0;
}

@media only screen and (max-width: 1500px) {
    .mission-community-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .mission-statement {
        padding-bottom: 2em;
    }
}

@media only screen and (max-width: 1100px) {
    .mission-image {
        width: 450px;
        height: 500px;
    }
    .mission-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .vision-statement {
        -webkit-align-items: center;
                align-items: center;
        margin-top: 3em;
    }
}

@media only screen and (max-width: 1024px) {
    .mission-container {
        width: 100%;
    }
    .mission-community-container {
        width: 100% !important;
        
    }
    .service-container {
        width: 90%;
    }
    .service-type {
        font-size: 25px;
    }
    .this-month-title {
        width: 100%;
    }
    .this-month-scripture, .this-month-hymn, .this-month-word {
        width: 70%;
        font-size: 19px;
        border-left: none;
        margin-top: 1em;
    }
    .this-month-scripture > p, .this-month-hymn > p, .this-month-word > p {
        padding-top: 1em;
        padding-left: 0;
        
    }
    .this-month-scripture , .this-month-hymn  {
        border-left: 2px solid #fff;
        padding-left: 1em;
    }
    .this-month-scripture b, .this-month-hymn b {
        padding-left: 0;
    }
}

@media only screen and (max-width: 850px) {
    .mission-container {
        padding-bottom: 0;
    }
    .service {
        padding-bottom: 2em;
    }
    .service-container {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        width: 95%;
    }
    .service-time-container {
        margin-bottom: 3em;
    }
    .service-type, .service-type span, .this-month-scripture, .this-month-hymn, .this-month-word {
        font-size: 18px;
    }`
    .outer-service-container, .this-month-container {
        background-attachment: scroll;
        background-attachment: initial;
    }

}

@media only screen and (max-width: 700px) {
    .mission-statement, .vision-statement {
        text-align: center;
        font-size: 18px;
        width: 95%;
    }
    .vision-statement-korean {
        width: 95%;
    }
    .mission-statement p {
        line-height: 1.5em;
        width: 100%;
    }

}

@media only screen and (max-width: 565px) {
    .mission-statement, .vision-statement, .this-month-scripture, .this-month-hymn, .this-month-word {
        font-size: 15px;
    }
    .mission-statement {
        width: 100%;
        -webkit-align-items: center;
                align-items: center;
    }
    .mission-statement span, .vision-statement span {
        display: block;
        margin: 0 auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-top: 0.5em;
        line-height: 1.3em;
    }
    .service {
        padding-bottom: 1.5em;
        font-size: 30px;
    }
    .main-page-location-container, .service-type span {
        font-size: 16px;
    }
    .this-month-scripture, .this-month-hymn, .this-month-word {
        width: 90%;
    }

    .email-icon {
        margin-top: 0.3em !important;
    }
    .phone-icon {
        margin-top: 0.1em !important;
    }
}

@media only screen and (max-width: 500px) {
    .mission-community-container {
        padding-bottom: 0;
    }
    .mission-image {
        
        width: 100%;
        height: 550px;
    }
}

@media only screen and (max-width: 450px) {
    .mission-image {
        height: 500px;
    }
}
@media only screen and (max-width: 400px) {
    .mission-image {
        height: 450px;
    }
}
@media only screen and (max-width: 350px) {
    .mission-image {
        height: 400px;
    }
}

.about-header {
    width: 100%;
    height: 70vh;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ), url(/static/media/about.3e425fd2.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s;
    background-position: 75%;
}


.header-title {
    position: absolute;
    color: #fff;
    text-align: center;
    top: 27vh;
    /* padding-right: 2.5em; */
    right: 5vh;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    top: 20vh; */
    /* height: 50vh; */
    /* padding-top: 100px; */
    /* margin-left: 1em;
    transform: translateX(-6%); */

}

.about-header p {
    font-size: 40px;
}

.header-pop {
    position: absolute;
    color: #fff;
    text-align: center;
    top: 37vh;
    /* left: 5vh; */
    /* padding-left: 2.5em; */
    /* right: 10%; */
    /* left: 5%; */
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; */
    /* height: 10vh; */
    /* padding-top: 100px; */
    /* margin-left: 1em; */
    /* transform: translateX(-6%); */
}

/* .about-header:hover > .header-title {
    transform: translateX(-6%);
} */

/* .about-header:hover > .header-pop {
    transform: translateY(-20vh);
    opacity: 1;
} */

/* .hover-space:hover ~ .header-pop {
    transform: translateY(-20vh);
    opacity: 1;
}

.hover-space {
    width: 100%;    
    border: 170px solid transparent;
    
} */

.about-mission-statement-container {
    padding: 0em 0 8em 0;
    width: 100%; 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
}

.about-mission-statement-welcome {
    display: -webkit-flex;
    display: flex;
    height: 350px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: 'Indie Flower', cursive;
    font-size: 60px;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1) ),url('../images/newFamily.jpg'); */
    /* background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */
    /* border-top: 1px solid #fff; */
    color: #333;
}

.about-liner {
    border-bottom: 4px solid;
    width: 7%;
    margin: 0 auto;
    margin-bottom: 5em;
    /* margin-top: 1em; */
}

.about-mission-statement {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 70%;
    margin: 0 auto;
    font-size: 18px;
    /* background-color: #F1EFD6; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 2em;
}

.about-images-container {
    -webkit-flex-direction: row;
            flex-direction: row;
}

.pastor-image {
    width: 280px;
    height: 250px;
    padding-bottom: 2em;
}


.about-mission-statement h3 {
    border-left: 5px solid #333;
    padding-left: 15px;
}

.about-mission-statement p {
    padding-left: 20px;
}



.new-family {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%;
}

.new-family-container h1 {
    padding-bottom: 1em;
}


.box {
    height: 100px;
    width: 100px;
    background: #fff;
}


@media only screen and (max-width: 850px) {
    /* .about-header {
        margin-left: 0;
    } */
    .about-mission-statement-welcome {
        background-attachment: scroll;
        background-attachment: initial;
    }
    .about-mission-statement h3 {
        font-size: 22px;
    }
    .about-mission-statement {
        font-size: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .about-mission-statement {
        width: 90%;
    }
    .about-mission-statement p {
        font-size: 14px;
    }
    
    .about-mission-statement h3 {
        font-size: 20px;
    }
    .about-images-container {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .pastor-image {
        width: 180px;
        height: 190px;
    }
    .about-header p {
        font-size: 35px;
    }
    /* .header-title {
        margin-right: 2em;
    } */
    /* .header-pop {
        transform: translateY(-210%);
        opacity: 1;
        left: 35%;
    } */
}

@media only screen and (max-width: 400px) {
    .hover-space {
        border: 150px solid transparent;
    }
    
    .about-mission-statement h3 {
        font-size: 18px;
    }
}
.location-header {
    width: 100%;
    height: 70vh;
}

.location-header:hover > h2 > span {
    color: #E67E22;
    transition: 0.5s;
}

.location-header {
    background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1) ), url(/static/media/location.d182b675.png);
    background-repeat: no-repeat;
    -ms-background-size: cover;
    background-size: cover;
    background-position: right;
}

.fas.fa-chevron-down, .location-header h2 {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 32vh;
    color: #fff;
    font-weight: bold;
    font-size: 60px;
    transition: 0.5s;
}

.fas.fa-chevron-down {
    top: 62vh;
    font-size: 50px;
}

.fas.fas.fa-chevron-down:hover {
    color: #229954 !important;
}

.location-wrapper { 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
}

.location-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 85%;
    padding-top: 10em;
    padding-bottom: 10em;
    margin: 0 auto;

}

.google-map-api {
    width: 800px;
    height: 500px;
    border: none;
    margin: auto 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.location-description {
    font-size: 20px;
    color: #333;
}

.location-description h3 {
    border-left: 4px solid;
    padding-left: 15px;
}

.address, .address-office, .parking, .email, .phone {
    padding-bottom: 1em;
}

.address h3, .address-office h3 {
    border-color: #229954;
}

.parking h3 {
    border-color: #E67E22;
}
.parking p {
    width: 80%
}

.email h3, .phone h3 {
    border-color: #2471A3
}

.location-description p {
    padding-left: 19px;
}

@media only screen and (max-width: 1440px) {
    .google-map {
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .location-container {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-align-items: center;
                align-items: center;
        padding-top: 0;
        padding-bottom: 2em;
        width: 100%;
    }
    /* .phone {
        margin-bottom: 3em;
    } */
    .google-map-api {
        width: 100%;
        height: 400px;
        margin-bottom: 5em;
        border-top: 1px solid #333;
    }
    .address, .address-office, .parking, .email, .phone {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        text-align: center;
        /* padding-bottom: 1em; */
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1024px) {    
    .location-description h3 {
        line-height: 0.5em;
        padding: 0.2em 0.4em;
    }
    .location-description {
        width: 100%;
    }
    .location-description p {
        padding-left: 0;
    }
}

@media only screen and (max-width: 750px) {
    .location-header h2 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .location-description {
        font-size: 15px;
    }
}
.space375, .space320, .space411 {
    display: none;
}
.news-header {
    width: 100%;
    height: 70vh;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1) ), url(/static/media/news.720f3bcc.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.news-title {
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;    
    height: 60vh;
    font-size: 18px;
    width: 60%;
}

.announcement-outer-container { 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
    padding: 5em 10em 10em 10em;
    color: #333;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.announcement-container {
    width: 100%;
    font-family: 'Do Hyeon', sans-serif;
    font-size: 18px;
}

.announcements-place-detail-container {
    font-size: 25px;
}

.announcement-header {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding-left: 5em;
    padding-bottom: 2em;
}

.news-liner {
    border-top: 5px solid;
    width: 5%;
    margin: 0 auto;
    padding-bottom: 2em;
}

.announcements {
    display: -webkit-flex;
    display: flex;
    padding: 1em 0 1em 0;
    padding-left: 3em;
    -webkit-align-items: center;
            align-items: center;
}

.date-box {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    width: 170px;
    height: 170px;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    font-size: 23px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.announcements-place-detail-container p {
    /* line-height: 0em; */
    margin-left: 2em;
    margin-bottom: 0.3em;

}

.announcements-date-place, .announcements-title {
    font-weight: bold;
}

.weekly-paper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 3em;
}

.weekly-paper span {
    padding: 1em 2em;
    cursor: pointer;
    background: #2471A3;
    color: #fff;
    transition: 0.5s;
}


.pdf-popup-btn {
    padding: 1em;
    cursor:pointer;
    background: #333;
    color: #fff;
    transition: 0.5s;
}

.weekly-paper span:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.announcements-other {
    width: 30%;
    border-left: 2px solid #333;
    font-family: 'Do Hyeon', sans-serif;
}

.announcements-other-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

@media only screen and (max-width: 1200px) {
    .announcement-outer-container {
        padding: 10em 2em 10em 2em;
    }
}

@media only screen and (max-width: 1024px) {
    .announcement-outer-container {
        display: inherit;

    }
    .announcement-container {
        width: 100%;
    }
    .announcements-other {
        margin-top: 3em;
        width: 100%;
        border-left: 0;
    }
    .announcement-header {
        -webkit-justify-content: center;
                justify-content: center;
        padding-left: 0;
    }
    .announcements {
        padding-left: 0;
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 950px) {
    .announcements-place-detail-container, .date-box {
        font-size: 20px;
    }
}
@media only screen and (max-width: 850px) {
    .announcements {
        width: 95%;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .date-box-container {
        width: 25%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .date-box {
        width: 150px;
        height: 150px;
    }
    .announcements-place-detail-container {
        width: 100%;
    }
}

@media only screen and (max-width: 750px) {
    .date-box {
        height: 150px;
        width: 100px;
        font-size: 20px;
    }

    .announcement-outer-container {
        padding: 5em 0 5em 0;
    }
    .announcements-place-detail-container {
        font-size: 17px;
    }
    .announcements-place-detail-container p {
        margin-left: 1em;
    }
    .announcements {
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }
    /* .announcements-place-detail-container span {
        padding-left: 0;
    } */
    .news-title {
        width: 100%;
        text-align: end;
    }
}

@media only screen and (min-width: 1500px) {
    .announcements {
        padding-left: 7em;
    }
    .announcement-header {
        padding-left: 17em;
    }
}

@media only screen and (max-width: 500px) {
    .news-title {
        font-size: 16px;
    }
    .date-box {
        height: 120px;
        width: 70px;
        font-size: 15px;
    }
    .announcements-place-detail-container {
        font-size: 16px;
    }

}

@media only screen and (max-width: 414px) {
    .space411 {
        display: inline;
        display: initial;
    }
}

@media only screen and (max-width: 375px) {
    .space411 {
        display: none;
    }
    .space375 {
        display: inline;
        display: initial;
    }
}

@media only screen and (max-width: 320px) {
    .space411 {
        display: none;
    }
    .space375 {
        display: none;
    }
    .space320 {
        display: inline;
        display: initial;
    }
    .date-box {
        width: 65px;
    }
    .announcements-detail {
        font-size: 13px;
    }
}
.last-weekly-update-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 10000;
    transition: 0.5s;
}

.last-weekly-update-popup {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.last-weekly-update-container button {
    background: #333;
    color: #fff;
    font-size: 14px;
}


.last-weekly-update-container img {
    width: 900px; 
    height: 90vh;
}

/* @media only screen and (min-width: 1441px) {
    .last-weekly-update-container img {
        width: 1000px;
        height: 650px
    }
} */

@media only screen and (max-width: 1024px) {
    .last-weekly-update-container img {
        width: 700px;
        height: auto;
    }
}

@media only screen and (max-width: 700px) {
    .last-weekly-update-container img{
        width: 550px;
        /* height: 650px; */
    }
}

@media only screen and (max-width: 600px) {
    .last-weekly-update-container img {
        width: 450px;
        /* height: 650px */
    }
}

@media only screen and (max-width: 500px) {
    .last-weekly-update-container img {
        width: 100%;
        /* height: 650px */
    }

}

@media only screen and (max-width: 450px) {
    .last-weekly-update-container img {
        width: 100%;
        height: auto;
    }

}
.pdf-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 10000;
    transition: 0.5s;
}

.pdf-popup-container {
    width: 100%;
    height: 90%;
}

.pdf-popup-container iframe {
    width: 100%;
    height: 100%;
}
.sermon-banner {
    position: relative;
    width: 100%;
    height: 70vh;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ), url(/static/media/sermon.2b3e017f.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s;
    background-position: center;
}

.sermon-banner p {    
    color: #fff;
    height: 60vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 80%;
    font-size: 18px;
    text-align: end;
}

.sermon-container { 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
    padding-top: 5em;
    padding-bottom: 4em;
}

.sermon-header-container {
    width: 55%;
    margin: 0 auto;
}

.sermon-container h1 {
    font-family: 'Do Hyeon', sans-serif;    
}

.sermon-tab-year-container {
    display: -webkit-flex;
    display: flex;
}

.sermon-tab-year {
    padding: 10px;
    cursor: pointer;        
}

.sermon-lists {
    height: 100%;
    width: 80%;
    margin: 0 auto;

}

.sermon-lists tr {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.sermon-lists th {
    display: -webkit-flex;
    display: flex;
    margin: 0.3em;
    width: 300px;
    height: 200px;
    cursor: pointer;
}

.sermon-lists th:hover {
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    transition: 0.5s;
}

@media only screen and (max-width: 1024px) {
    .sermon-banner p {
        width: 90%;
        font-size: 16px;
    }
}

@media only screen and (max-width: 800px) {
    .sermon-container h1  {
        padding-left: 0;
        text-align: center;
    }
    .sermon-tab-year-container {
        -webkit-justify-content: center;
                justify-content: center;
    }
}



.confirm-button {
    padding: 10px;
    border: none;
    border-radius: 10px;
    margin-right: 10px;
    background: #e7bb42;
    color: #fff;
    font-weight: bold;
}
.carousel-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    /* top: 0; */
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 10000;
    overflow: auto;
}

.carousel-popup-container {
    overflow: auto;
    max-height: 550px;
    top: 10%;
    background: url(/static/media/covid19.3163a84f.jpg);
    background-size: cover;
    width: 70%;
    padding: 1em;
    margin-top: 10em;
}

.covid-19-container {
    /* display: flex; */
    color: #fff;
}

.bottom {
    margin-top: 2em;
    text-align: center;
}

.bottm-btn {
    float: none;
}

.left {
    /* width: 45%; */
    /* margin-top: 3em; */
}

.right {
    padding-left: 2em;
    padding-right: 2em;
}

.close span {
    color: #fff;
    font-size: 30px;
    transition: 0.3s;
    padding-bottom: 0.3em;
    outline: none;
}

.close span:hover {
    color: #E67E22;
    outline: none;
}

.carousel-popup-container video {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    outline: none;
}


@media only screen and (max-width: 1024px) {
    .carousel-popup-container {
        width: 85%;
    }
}

@media only screen and (max-width: 600px) {
    .carousel-popup-container {
        width: 95%;
    }
}


.EMPS-banner {
    position: relative;
    width: 100%;
    height: 70vh;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ), url(/static/media/EMPS.0222b877.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s;
    background-position: top;
}

.EMPS-banner p {    
    color: #fff;
    height: 60vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 80%;
    font-size: 18px;
    text-align: end;
}

.EMPS-table-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 5em 0 5em 0;
}

.EMPS-table-container table {
    width: 50%
}

.EMPS-table-container th, .EMPS-table-container td{
    padding: 1em;
}

.EMPS-table-container button {
    padding: 0 0.5em;
    border-radius: 5px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

@media only screen and (max-width: 1024px) {
    .EMPS-banner p {
        width: 90%;
        font-size: 16px;
    }
    .EMPS-table-container table {
        width: 70%
    }
}

@media only screen and (max-width: 600px) {
    .EMPS-table-container table {
        width: 95%
    }
    .EMPS-table-container {
        font-size: 15px;
    }
    .EMPS-table-container th, .EMPS-table-container td{
        padding: 0.5em;
    }
}
.bible-audio-banner {
  position: relative;
  width: 100%;
  height: 70vh;
  background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
    url(/static/media/sermon.2b3e017f.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 1s;
  background-position: center;
}

.bible-audio-banner p {
  color: #fff;
  height: 60vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 80%;
  font-size: 18px;
  text-align: end;
}
@media only screen and (max-width: 768px) {
  .bible-audio-banner p {
    width: 95%;
  }
}

.bible-audio-container {
  background: linear-gradient(to right, #fdfcfb, #e2d1c3);
  padding-top: 5em;
  padding-bottom: 4em;
}

.testament-container {
  display: -webkit-flex;
  display: flex;
  width: 70%;
  margin: 0 auto;
}

.testament-container > span {
  padding: 10px;
  cursor: pointer;
}

.audio-outer-container {
  display: -webkit-flex;
  display: flex;
  width: 70%;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 auto;
  margin-top: 30px;
}
@media only screen and (max-width: 768px) {
  .audio-outer-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: start;
            align-items: start;
  }
}

.audio-outer-container img {
  margin-right: 20px;
}

.audio-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.audio-details {
  padding: 5px;
}

.books-container {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
}

.books-menu-outer-container {
  display: -webkit-flex;
  display: flex;
}

.books-menu-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 140px;
  max-width: 140px;
}

.book {
  cursor: pointer;
  padding: 5px;
}

.audio-list-container {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.audio-list-container span {
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.page {
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  color: #fff;
  background: #e67e22;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
}

