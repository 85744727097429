.location-header {
    width: 100%;
    height: 70vh;
}

.location-header:hover > h2 > span {
    color: #E67E22;
    transition: 0.5s;
}

.location-header {
    background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1) ), url("../images/location.png");
    background-repeat: no-repeat;
    -ms-background-size: cover;
    background-size: cover;
    background-position: right;
}

.fas.fa-chevron-down, .location-header h2 {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 32vh;
    color: #fff;
    font-weight: bold;
    font-size: 60px;
    transition: 0.5s;
}

.fas.fa-chevron-down {
    top: 62vh;
    font-size: 50px;
}

.fas.fas.fa-chevron-down:hover {
    color: #229954 !important;
}

.location-wrapper {
    background: -webkit-linear-gradient(to right, #fdfcfb, #e2d1c3); 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
}

.location-container {
    display: flex;
    justify-content: space-evenly;
    width: 85%;
    padding-top: 10em;
    padding-bottom: 10em;
    margin: 0 auto;

}

.google-map-api {
    width: 800px;
    height: 500px;
    border: none;
    margin: auto 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.location-description {
    font-size: 20px;
    color: #333;
}

.location-description h3 {
    border-left: 4px solid;
    padding-left: 15px;
}

.address, .address-office, .parking, .email, .phone {
    padding-bottom: 1em;
}

.address h3, .address-office h3 {
    border-color: #229954;
}

.parking h3 {
    border-color: #E67E22;
}
.parking p {
    width: 80%
}

.email h3, .phone h3 {
    border-color: #2471A3
}

.location-description p {
    padding-left: 19px;
}

@media only screen and (max-width: 1440px) {
    .google-map {
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .location-container {
        flex-direction: column-reverse;
        align-items: center;
        padding-top: 0;
        padding-bottom: 2em;
        width: 100%;
    }
    /* .phone {
        margin-bottom: 3em;
    } */
    .google-map-api {
        width: 100%;
        height: 400px;
        margin-bottom: 5em;
        border-top: 1px solid #333;
    }
    .address, .address-office, .parking, .email, .phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        /* padding-bottom: 1em; */
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1024px) {    
    .location-description h3 {
        line-height: 0.5em;
        padding: 0.2em 0.4em;
    }
    .location-description {
        width: 100%;
    }
    .location-description p {
        padding-left: 0;
    }
}

@media only screen and (max-width: 750px) {
    .location-header h2 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .location-description {
        font-size: 15px;
    }
}