.mission-container, .mission-community-container {
    display: flex;
    justify-content: space-around;
    width: 70%;
    margin: 0 auto;
    padding-bottom: 3em;
    color: #333;
}

.outer-mission-container {
    background: -webkit-linear-gradient(to right, #fdfcfb, #e2d1c3); 
    background: linear-gradient(to right, #fdfcfb, #e2d1c3); 
    padding-top: 5em
}

.mission-statement, .vision-statement {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    justify-content: center;
}

.mission-statement h2, .mission-statement span {
    width: 100%;
    background: #154360;
    color: #fff;
    padding: 0.05em 0.5em;
    
    /* margin-bottom: 1em; */
    /* border-radius: 3px; */
}

.vision-logo-container {
    display: flex;
    align-items: center;
}

i {
    font-size: 110px;
}

.mission-image {
    width: 500px;
    height: 550px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.outer-service-container {
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/service.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 9em;
    padding-bottom: 9em;
    color: #ECF0F1;
    width: 100%;
}

.service-container {
    display: flex;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;
}

.service {
    font-size: 40px;
    width: fit-content;
    margin: 0 auto;
    padding: 0.5em 0 3em;
}

.service-liner {
    content: "";
    width: 5%;
    border-top: 5px solid #fff;
    margin: 0 auto;
    padding-bottom: 2em;
}

.service-time-container {
    display: flex;
    flex-direction: column;
}

.service-sunday, .service-saturday, .service-friday {
    border-bottom: 1px solid;
    padding: 1em;
}

.service-type {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 0.5em;
    width: fit-content;
}

.service-type type {
    border-right: 4px solid #fff;
    line-height: 0.8;
    padding-right: 0.7em;
    width: fit-content
}

.service-type span {
    font-size: 23px;
    font-weight: normal;
    padding-left: 0.7em;
}

.service-time {
    font-size: 20px;
}

.main-page-location-container {
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.main-page-location, .main-page-email, .main-page-phone {
    display: flex;
}

.main-page-location ul, .main-page-email ul, .main-page-phone ul {
    list-style: none;
    padding-left: 1em;
}

.main-page-location b, .main-page-email b {
    padding-bottom: 0.3em;
}

.main-page-email a, .main-page-location a {
    text-decoration: underline;
    text-underline-position: under;
    color: #ECF0F1;
    transition: 0.3s;
}

.main-page-email a:hover, .main-page-location a:hover {
    color: #5499C7;
}

.this-month-container {
    background: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3) ), url('../images/thisMonth.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    padding: 9em 0 9em 0;
    
    color: #fff;
    border-top: 1px solid #fff;
    width: 100%;
    height: auto;
}

.this-month-title {
    display: flex;
    justify-content: center;
    width: 50%;
    padding-bottom: 1em;
}

.this-month-title {
    font-size: 30px;
}

.this-month-scripture, .this-month-hymn {
    border-left: 5px solid;
    width: 65%;
    margin: 0 auto;
    font-size: 20px;
}

.this-month-word {
    width: 63%;
    margin: 0 auto;
    font-size: 20px;
    /* line-height: 1.5em; */
}


.this-month-scripture > *, .this-month-hymn > *, .this-month-word > * {
    padding-left: 2em;
}

.this-month-word-author {
    line-height: 0;
}

@media only screen and (max-width: 1500px) {
    .mission-community-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mission-statement {
        padding-bottom: 2em;
    }
}

@media only screen and (max-width: 1100px) {
    .mission-image {
        width: 450px;
        height: 500px;
    }
    .mission-container {
        flex-direction: column;
        align-items: center;
    }
    .vision-statement {
        align-items: center;
        margin-top: 3em;
    }
}

@media only screen and (max-width: 1024px) {
    .mission-container {
        width: 100%;
    }
    .mission-community-container {
        width: 100% !important;
        
    }
    .service-container {
        width: 90%;
    }
    .service-type {
        font-size: 25px;
    }
    .this-month-title {
        width: 100%;
    }
    .this-month-scripture, .this-month-hymn, .this-month-word {
        width: 70%;
        font-size: 19px;
        border-left: none;
        margin-top: 1em;
    }
    .this-month-scripture > p, .this-month-hymn > p, .this-month-word > p {
        padding-top: 1em;
        padding-left: 0;
        
    }
    .this-month-scripture , .this-month-hymn  {
        border-left: 2px solid #fff;
        padding-left: 1em;
    }
    .this-month-scripture b, .this-month-hymn b {
        padding-left: 0;
    }
}

@media only screen and (max-width: 850px) {
    .mission-container {
        padding-bottom: 0;
    }
    .service {
        padding-bottom: 2em;
    }
    .service-container {
        flex-direction: column;
        align-items: center;
        width: 95%;
    }
    .service-time-container {
        margin-bottom: 3em;
    }
    .service-type, .service-type span, .this-month-scripture, .this-month-hymn, .this-month-word {
        font-size: 18px;
    }`
    .outer-service-container, .this-month-container {
        background-attachment: initial;
    }

}

@media only screen and (max-width: 700px) {
    .mission-statement, .vision-statement {
        text-align: center;
        font-size: 18px;
        width: 95%;
    }
    .vision-statement-korean {
        width: 95%;
    }
    .mission-statement p {
        line-height: 1.5em;
        width: 100%;
    }

}

@media only screen and (max-width: 565px) {
    .mission-statement, .vision-statement, .this-month-scripture, .this-month-hymn, .this-month-word {
        font-size: 15px;
    }
    .mission-statement {
        width: 100%;
        align-items: center;
    }
    .mission-statement span, .vision-statement span {
        display: block;
        margin: 0 auto;
        width: fit-content;
        margin-top: 0.5em;
        line-height: 1.3em;
    }
    .service {
        padding-bottom: 1.5em;
        font-size: 30px;
    }
    .main-page-location-container, .service-type span {
        font-size: 16px;
    }
    .this-month-scripture, .this-month-hymn, .this-month-word {
        width: 90%;
    }

    .email-icon {
        margin-top: 0.3em !important;
    }
    .phone-icon {
        margin-top: 0.1em !important;
    }
}

@media only screen and (max-width: 500px) {
    .mission-community-container {
        padding-bottom: 0;
    }
    .mission-image {
        
        width: 100%;
        height: 550px;
    }
}

@media only screen and (max-width: 450px) {
    .mission-image {
        height: 500px;
    }
}
@media only screen and (max-width: 400px) {
    .mission-image {
        height: 450px;
    }
}
@media only screen and (max-width: 350px) {
    .mission-image {
        height: 400px;
    }
}
