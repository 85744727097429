.sermon-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.sermon-popup-container {
  width: fit-content;
  height: fit-content;
  padding: 1em;
  background-color: #fff;
}

.sermon-video-popup-container {
  width: 50%;
}

.sermon-video-popup-container video {
  display: flex;
  justify-content: center;
  width: 100%;
  outline: none;
}

.sermon-close,
.sermon-video-close {
  background: transparent;
  border: none;
  font-size: 20px;
  float: right;
}

.sermon-video-close span {
  color: #fff;
  outline: none;
  transition: 0.3s;
}

.sermon-close span {
  color: #333;
  outline: none;
  transition: 0.3s;
}

.sermon-close span:hover,
.sermon-video-close span:hover {
  color: #e67e22;
  outline: none;
}

.sermon-popup-container img {
  border-radius: 50%;
}

.sermon-popup-container p {
  padding-left: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
}

.sermonVideoRefButton {
  border-style: none;
  padding: 0.3em;
  margin-top: 0.3em;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.sermon-popup-container audio {
  padding-top: 0.5em;
  display: flex;
  justify-content: center;
  width: 100%;
}

.sermon-popup-container audio:focus {
  outline: none;
}
@media only screen and (max-width: 800px) {
  .sermon-video-popup-container {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .sermon-video-popup-container {
    width: 100%;
  }
}
