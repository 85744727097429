.EMPS-banner {
    position: relative;
    width: 100%;
    height: 70vh;
    background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ), url("../images/EMPS.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1s;
    background-position: top;
}

.EMPS-banner p {    
    color: #fff;
    height: 60vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 80%;
    font-size: 18px;
    text-align: end;
}

.EMPS-table-container {
    display: flex;
    justify-content: center;
    margin: 5em 0 5em 0;
}

.EMPS-table-container table {
    width: 50%
}

.EMPS-table-container th, .EMPS-table-container td{
    padding: 1em;
}

.EMPS-table-container button {
    padding: 0 0.5em;
    border-radius: 5px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

@media only screen and (max-width: 1024px) {
    .EMPS-banner p {
        width: 90%;
        font-size: 16px;
    }
    .EMPS-table-container table {
        width: 70%
    }
}

@media only screen and (max-width: 600px) {
    .EMPS-table-container table {
        width: 95%
    }
    .EMPS-table-container {
        font-size: 15px;
    }
    .EMPS-table-container th, .EMPS-table-container td{
        padding: 0.5em;
    }
}