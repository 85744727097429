.carousel-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    /* top: 0; */
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    overflow: auto;
}

.carousel-popup-container {
    overflow: auto;
    max-height: 550px;
    top: 10%;
    background: url('../images/covid19.jpg');
    background-size: cover;
    width: 70%;
    padding: 1em;
    margin-top: 10em;
}

.covid-19-container {
    /* display: flex; */
    color: #fff;
}

.bottom {
    margin-top: 2em;
    text-align: center;
}

.bottm-btn {
    float: none;
}

.left {
    /* width: 45%; */
    /* margin-top: 3em; */
}

.right {
    padding-left: 2em;
    padding-right: 2em;
}

.close span {
    color: #fff;
    font-size: 30px;
    transition: 0.3s;
    padding-bottom: 0.3em;
    outline: none;
}

.close span:hover {
    color: #E67E22;
    outline: none;
}

.carousel-popup-container video {
    display: flex;
    justify-content: center;
    width: 100%;
    outline: none;
}


@media only screen and (max-width: 1024px) {
    .carousel-popup-container {
        width: 85%;
    }
}

@media only screen and (max-width: 600px) {
    .carousel-popup-container {
        width: 95%;
    }
}

