.nav-container {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    z-index: 2;
    /* background-color: transparent; */
    height: 75px;
    align-items: center;
    /* border-bottom: 1px solid #A6ACAF; */
    transition: 0.5s;
}

.nav {
    padding-right: 10em;
}

.nav-link {
    color: #fff;
    padding: 0 3em 0 0.5em;
    display: initial;
    border-left: 4px solid #2471A3;
    font-weight: 600;
    transition: 0.5s;
}

.nav-link::before {
    border-right: 2px solid;
}

.nav-link:hover, .nav-home:hover, .resp-nav-home:hover {
    color: #5499C7 !important;
    text-decoration: none;
}

.nav-home, .resp-nav-home {
    margin-left: 10em;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    transition: 0.5s;
    color: #fff;
    line-height: 1em;
}

.resp-nav-home {
    width: 150px;
}

.nav-home img, .resp-nav-home img {
    width: 20px;
    height: 20px;
    margin-bottom: 0.25em;
    margin-right: 0.5em;
}

.resp-nav-home {
    display: none;
}

.JGCC {
    font-size: 14px;
    margin-left: 22px;
}

.JGCC span {
    color: #E67E22
}

.nav-logo {
    width: 160px;
    height: 70px;
    margin-left: 5em;
}

.nav-menu {
    font-size: 30px;
    cursor: pointer;
    display: none;
    padding-right: 5em;
    color: #fff;
}

@media only screen and (max-width: 1300px) {
    .nav {
        display: none;
    }
    .nav-menu {
        display: initial;
    }
}

@media only screen and (max-width: 1024px) {
    .resp-nav-home {
        display: initial;
    }
    .nav-home {
        display: none;
    }
}

@media only screen and (max-width: 850px) {
    .resp-nav-home {
        margin-left: 3em;
    }
    .nav-menu {
        padding-right: 3em;
    }
}

@media only screen and (max-width: 450px) {
    .nav-home, .resp-nav-home {
        font-size: 13px;
    }
    .nav-menu {
        padding-right: 1.5em;
    }
}
